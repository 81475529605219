.checkbox input:checked {
  border-color: #239fa7;
  background-color: #239fa7;
}

.rdr-Day.is-inRange {
  background: rgba(35, 159, 167, 0.1) !important;
}

.rdr-Day.is-selected {
  background-color: #239fa7 !important;
  border-radius: 8px;
}

.rdr-DateRange {
  text-align: center;
}

/* tabs */
.nav-tabs .tw-nav-link.active {
  color: #239fa7;
  border-color: #239fa7;
}

.tw-nav-active::before,
.tw-nav-active::after {
  content: "";
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
}

.swiper-container.tw-title-top .swiper-button-prev,
.swiper-container.tw-title-top .swiper-button-next {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 5px;
  margin-top: 0;
  width: 42px;
  height: 42px;
  border-radius: 100%;
  border: 1px solid #efeff8;
  box-shadow: 0px 3px 10px rgba(85, 95, 109, 0.08);
}

.swiper-container.tw-title-top .swiper-button-prev::after,
.swiper-container.tw-title-top .swiper-button-next::after {
  font-size: 12px;
  font-weight: 600;
  color: #292e42;
}

.swiper-container.tw-title-top .swiper-button-next {
  right: 0;
}

.swiper-container.tw-title-top .swiper-button-prev {
  right: 52px;
  left: auto;
}

select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1.5rem center;
  background-size: 1em;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
